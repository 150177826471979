export const dataURLtoBlob = (dataurl: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const sortFilterToOrderByKey = (sortFilter: string) => {
  switch (sortFilter) {
    case "Created At: Latest":
    case "Created At: Earliest":
      return "created_at";
    case "Price: High to low":
    case "Price: Low to high":
      return "price";
    case "Name: A to Z":
    case "Name: Z to A":
      return "name";
    default:
      return "created_at";
  }
};

export const sortFilterToOrderByValue = (sortFilter: string) => {
  switch (sortFilter) {
    case "Created At: Latest":
    case "Name: Z to A":
    case "Price: High to low":
      return "DESC";
    case "Created At: Earliest":
    case "Name: A to Z":
    case "Price: Low to high":
      return "ASC";
    default:
      return "DESC";
  }
};
