import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";
import { store } from "./redux";
import Router from "./containers/Router";
// import reportWebVitals from './reportWebVitals';

import "./index.css";

const stripePubKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUB_PROD_KEY
    : process.env.REACT_APP_STRIPE_PUB_TEST_KEY || "stripeApiKey";
const stripePromise = loadStripe(stripePubKey as string);

const App = () => (
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <Router />
    </Elements>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
