import React from "react";
import queryString from "query-string";
import {
  GetPublicPropertyResponse,
  SearchPublicPropertyStoreResponse,
} from "../../../interfaces/property";
import ProductService from "../../../services/Product";
import PropertyService from "../../../services/Property";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import "./index.css";
import { authRoutePath } from "../../Router";
import { errorsToLabels } from "../../../utils/errors";
import {
  ListProductsResponse,
  ListPublicProductsResponse,
} from "../../../interfaces/product";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { PayForProductResponse } from "../../../interfaces/payment";
import PaymentService from "../../../services/Payment";
import config from "../../../config";
import { ProductCard } from "../../../components/ProductCard";
import "./index.css";
import { Mixpanel } from "../../../utils/analytics";

class PublicProperty extends React.Component<any, any> {
  PaymentService: PaymentService;
  ProductService: ProductService;
  PropertyService: PropertyService;

  constructor(props: any) {
    super(props);

    this.PaymentService = new PaymentService();
    this.ProductService = new ProductService();
    this.PropertyService = new PropertyService();

    const parsedQuery = queryString.parse(this.props.location.search);
    if (!parsedQuery.id) {
      this.props.history.replace(authRoutePath, { session: false });
      return;
    }

    this.state = {
      imgIndex: Math.floor(Math.random() * 5),
      loading: true,
      propertyID: parsedQuery.id,
      searchValue: "",
      paginationOptions: {
        offset: 0,
        limit: 4,
        orderByKey: "created_at",
        orderByValue: "DESC",
      },
      products: [],
      selectedProduct: null,
      checkoutAmount: 0,
    };

    this.loadProductsData = this.loadProductsData.bind(this);
    this.handleLoadMoreProducts = this.handleLoadMoreProducts.bind(this);
    this.handlePayForProduct = this.handlePayForProduct.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.renderPropertyDetails = this.renderPropertyDetails.bind(this);
    this.renderProductsList = this.renderProductsList.bind(this);
    this.renderSelectedProductModal =
      this.renderSelectedProductModal.bind(this);
  }

  async componentDidMount() {
    try {
      // Get Public Property data
      const gpRes: GetPublicPropertyResponse =
        await this.PropertyService.getPublicProperty({
          id: this.state.propertyID,
        });
      if (gpRes.error) {
        throw new Error(gpRes.error);
      }

      // Get Public Property (Public) product data
      const listProdRes: ListPublicProductsResponse =
        await this.ProductService.listPublicProducts({
          propertyID: this.state.propertyID,
          offset: this.state.paginationOptions.offset,
          limit: this.state.paginationOptions.limit,
          orderByKey: "created_at",
          orderByValue: "DESC",
        });
      if (listProdRes.error) {
        throw new Error(listProdRes.error);
      }

      this.setState(
        {
          loading: false,
          totalFound: listProdRes.totalFound,
          property: gpRes.property,
          products: listProdRes.products,
        },
        () => {
          const parsedQuery = queryString.parse(this.props.location.search);
          if (parsedQuery.status) {
            if (parsedQuery.status === "1") {
              displayToastMessage("success", "Payment Succeeded");
            } else {
              displayToastMessage(
                "error",
                errorsToLabels.failedToPurchaseProduct
              );
            }
          }
          if (parsedQuery.ppc) {
            displayToastMessage(
              "info",
              `Property Passcode: ${parsedQuery.ppc}`,
              {
                autoClose: 15000,
                position: "bottom-center",
              }
            );
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Products.cdm()", error);
      displayToastMessage("error", errorsToLabels.failedToLoadProductData);
    }
  }

  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel)
    );
  }

  async loadProductsData() {
    try {
      const listProdRes: ListProductsResponse =
        await this.ProductService.listPublicProducts({
          propertyID: this.state.property.id,
          offset: this.state.paginationOptions.offset,
          limit: this.state.paginationOptions.limit,
          orderByKey: "created_at",
          orderByValue: "DESC",
        });
      if (listProdRes.error) {
        throw new Error(listProdRes.error);
      }

      this.setState({
        loading: false,
        totalFound: listProdRes.totalFound,
        products: listProdRes.products,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("PublicProperty.loadProductsData", error);
      displayToastMessage("error", errorsToLabels.failedToLoadProductData);
    }
  }

  handleLoadMoreProducts(offset: number) {
    this.setState(
      {
        paginationOptions: {
          ...this.state.paginationOptions,
          offset,
        },
      },
      async () => {
        displayToastMessage("info", "Loading");
        await this.loadProductsData();
      }
    );
  }

  renderLoadMoreButtons() {
    if (this.state.totalFound !== 0) {
      const { limit, offset } = this.state.paginationOptions;
      const disablePrevButton = offset === 0;
      const disableNextButton = offset + limit >= this.state.totalFound;
      return (
        <div className="load-buttons-container">
          <button
            disabled={disablePrevButton}
            className={`${
              disablePrevButton
                ? "disabled-load-button"
                : "pp-store-load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProducts(offset - limit)}
          >
            Prev
          </button>
          <button
            disabled={disableNextButton}
            className={`${
              disableNextButton
                ? "disabled-load-button"
                : "pp-store-load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProducts(offset + limit)}
          >
            Next
          </button>
        </div>
      );
    }
  }

  async handlePayForProduct(e: Event | any) {
    try {
      e.preventDefault();

      logIssueNetworkRequest("handlePayForProduct");

      displayToastMessage("info", "Loading...");

      const { selectedProduct, checkoutAmount, property } = this.state;
      if (selectedProduct.amount === 0) {
        return displayToastMessage("error", "Item out of stock");
      }
      if (checkoutAmount === 0) {
        return displayToastMessage("error", "Checkout amount can not be 0");
      }
      if (checkoutAmount > selectedProduct.amount) {
        return displayToastMessage(
          "error",
          `Checkout amount (${checkoutAmount}) can not be greater than item stock amount (${selectedProduct.amount})`
        );
      }

      const pfpRes: PayForProductResponse =
        await this.PaymentService.payForProduct({
          userID: property.user_id,
          propertyID: property.id,
          productID: selectedProduct.id,
          amount: checkoutAmount,
        });
      if (pfpRes.error) {
        throw new Error(pfpRes.error);
      }

      displayToastMessage("success", "Success");

      Mixpanel.track("Product Payment Checkout (started)", {});

      (window as Window).location = pfpRes.sessionURL;

      this.setState({ selectedProduct: null });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Products.handlePayForProduct", error);
      displayToastMessage("error", errorsToLabels.failedToPurchaseProduct);
    }
  }

  renderHeader() {
    return (
      <header
        onClick={(evt) => (window.location.href = config.links.baseClientURL)}
      >
        <h1>{config.labels.brandName}</h1>
      </header>
    );
  }

  renderPublicPropertyImage() {
    return (
      <div className="public-property-image-container col-md-4 col-12">
        <img
          alt="pp-image"
          className="desktop-tablet-pp-img"
          src={`${process.env.PUBLIC_URL}/pp-img-${this.state.imgIndex}.jpeg`}
        ></img>
        <img
          alt="pp-image"
          className="mobile-pp-img"
          src={`${process.env.PUBLIC_URL}/home-list-img.jpg`}
        />
      </div>
    );
  }

  renderPublicPropertyContent() {
    return (
      <div className="public-property-content-container col-md-8 col-12">
        {this.renderPropertyDetails()}
        <hr className="public-property-content-divider" />
        {this.renderProductsList()}
      </div>
    );
  }

  renderPropertyDetails() {
    if (this.state.property) {
      const propertyManager = () => {
        if (this.state.property.property_manager_email) {
          const pm = this.state.property.property_manager_email;
          return (
            <p>
              <strong>Property manager:</strong>{" "}
              <a href={`mailto://${pm}`}>{pm}</a>
            </p>
          );
        }
      };
      return (
        <div className="public-property-details-container">
          <h1>{this.state.property.address}</h1>
          <p>
            <strong>City:</strong> {this.state.property.city}
          </p>
          <p>
            <strong>State:</strong> {this.state.property.state}
          </p>
          <p>
            <strong>Zipcode:</strong> {this.state.property.zipcode}
          </p>
          {propertyManager()}
        </div>
      );
    }
  }

  async handleSearchProducts(e: Event | any) {
    try {
      if (e) e.preventDefault();

      displayToastMessage("info", "Loading...");

      if (this.state.searchValue === "") {
        await this.loadProductsData();
        return;
      }

      logIssueNetworkRequest("handleSearchProducts");

      const spRes: SearchPublicPropertyStoreResponse =
        await this.PropertyService.searchPublicPropertyStore({
          id: this.state.property.id,
          prompt: this.state.searchValue,
        });
      if (spRes.error) {
        throw new Error(spRes.error);
      }

      this.setState(
        {
          products: spRes.products,
        },
        () => {
          displayToastMessage("success", "Success");
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "PublicProperty.handleSearchProducts()",
        error
      );
      displayToastMessage("error", errorsToLabels.failedToLoadRecommendations);
    }
  }

  renderProductsList() {
    return (
      <div className="products-list row">
        <h1>Store</h1>
        <input
          className="pp-list-search-input"
          type="text"
          placeholder="Good date night options..."
          value={this.state.searchValue}
          onChange={(e: Event | any) => {
            this.setState(
              {
                searchValue: e.target.value,
              },
              async () => {
                if (this.state.searchValue === "") {
                  await this.loadProductsData();
                  return;
                }
              }
            );
          }}
          onSubmit={this.handleSearchProducts}
          onKeyDown={async (evt) => {
            if (evt.key === "Enter") {
              await this.handleSearchProducts(evt);
            }
          }}
        />
        {this.state.products.map((product: Object | any, i: number) => (
          <ProductCard
            key={i}
            index={i}
            currentView={"PublicProperty"}
            product={product}
            selectProduct={(evt: Event | any) => {
              evt.preventDefault();
              this.setState({ selectedProduct: product });
            }}
          />
        ))}
        {this.renderLoadMoreButtons()}
      </div>
    );
  }

  renderSelectedProductModal() {
    if (this.state.selectedProduct !== null) {
      return (
        <div className="generic-modal-container-active">
          <div className="generic-modal-container-overlay">
            <div className="selected-product-container row">
              <div className="selected-product-image-container col-md-6 col-12">
                <img
                  src={`${process.env.PUBLIC_URL}/home-list-img.jpg`}
                  onClick={(e: Event | any) => {
                    e.preventDefault();
                    this.setState({ selectedProduct: null });
                  }}
                />
              </div>
              <div className="selected-product-content-container col-md-6 col-12">
                <div className="selected-product-details-container">
                  <h1>{this.state.selectedProduct.name}</h1>
                  <p>
                    <strong>Price:</strong> ${this.state.selectedProduct.price}
                  </p>
                  <p>
                    <strong>Amount:</strong> {this.state.selectedProduct.amount}
                  </p>
                </div>
                <hr className="public-property-content-divider" />
                <div className="selected-product-checkout-container">
                  <h1>Checkout</h1>
                  <label>Amount</label>
                  <input
                    className="amount-input"
                    type="number"
                    placeholder="Amount"
                    value={this.state.checkoutAmount}
                    onChange={(e: Event | any) => {
                      e.preventDefault();
                      this.setState({ checkoutAmount: e.target.value }, () =>
                        logUpdatedState(this.state, "checkoutAmount")
                      );
                    }}
                  />
                  <button
                    className="selected-product-checkout-button cancel-button"
                    onClick={(e: Event | any) => {
                      e.preventDefault();
                      this.setState({ selectedProduct: null });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="selected-product-checkout-button"
                    onClick={this.handlePayForProduct}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderMainContent() {
    if (this.state.selectedProduct) {
      return this.renderSelectedProductModal();
    }
    return (
      <div className="public-property-main-content row">
        {this.renderPublicPropertyImage()}
        {this.renderPublicPropertyContent()}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="public-property-container">
        {this.renderHeader()}
        {this.renderMainContent()}
      </div>
    );
  }
}

export default PublicProperty;
