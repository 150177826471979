import { SET_USER, CLEAR_USER } from "../constants";
import { IPaymentAccount } from "../../interfaces/payment";

export type UserType = "inactive" | "creator" | "customer" | "business";

export interface IUser {
  active: boolean;
  email: string;
  activationToken?: string;
  activation_token?: string;
  passwordResetToken?: string;
  password_reset_token?: string;
  passwordResetExpires?: string;
  password_reset_expires?: string;
  twoFactorEnabled?: boolean;
  two_factor_enabled?: boolean;
  user_payment_account?: IPaymentAccount;
  createdAt?: string;
  created_at?: string;
}

export interface IUserAction {
  type: string;
  user?: IUser | any;
}

export const setUser = (user: IUser | any) => {
  return {
    type: SET_USER,
    user,
  };
};

export const clearUser = () => {
  return { type: CLEAR_USER };
};
