import CommonService from "../Common";
import {
  ListProductsRequest,
  ListPublicProductsRequest,
  GetProductRequest,
  CreateProductRequest,
  RecommendProductsRequest,
  UpdateProductRequest,
  DeleteProductRequest,
  UpdateProductImageRequest,
  GetPublicProductRequest,
} from "../../interfaces/product";

interface Report {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class ReportService implements Report {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/product";

    this.CommonService = new CommonService();
  }

  listProducts(reqBody: ListProductsRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/list`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  listPublicProducts(reqBody: ListPublicProductsRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/list/public`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  getProduct(reqBody: GetProductRequest) {
    const productID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${productID}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  getPublicProduct(reqBody: GetPublicProductRequest) {
    const productID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/public/${productID}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  createProduct(reqBody: CreateProductRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  recommendProducts(reqBody: RecommendProductsRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/ai/recommend`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  updateProduct(reqBody: UpdateProductRequest) {
    const productID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${productID}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  updateProductImage(reqBody: UpdateProductImageRequest) {
    const productID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${productID}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  deleteProduct(reqBody: DeleteProductRequest) {
    const productID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${productID}`, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default ReportService;
