import React from "react";
import { connect } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation";
import config from "../../config";
import { IUser } from "../../interfaces/user";
import { setUser } from "../../redux/actions/user";
import SessionService from "../../services/Session";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
} from "../../utils/loggers";
import { authRoutePath } from "../Router";
import Properties from "./Properties";
import Products from "./Products";
import Settings from "./Settings";
import { displayToastMessage } from "../../utils/toasts";
import { errorsToLabels } from "../../utils/errors";
import "./index.css";

class Home extends React.Component<any, any> {
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      currentView: "Properties",
      navBarItems: ["Properties", "Products", "Settings"],
      history: props.history,
      selectedProperty: null,
    };

    this.SessionService = new SessionService();

    this.renderCurrentHomeView = this.renderCurrentHomeView.bind(this);
    this.updateHomeView = this.updateHomeView.bind(this);
    this.updateSelectedProperty = this.updateSelectedProperty.bind(this);
  }

  async componentDidMount() {
    try {
      // Example: State transition from the Auth view
      if (
        this.props.location.state &&
        this.props.location.state.session &&
        this.props.user.id
      ) {
        return this.setState({ loading: false });
      }

      const rsRes = await this.SessionService.reviewSession();

      logIssueNetworkRequest("Home.reviewSession()");

      if (!rsRes.success) {
        await this.SessionService.signOut();
        return this.props.history.replace(authRoutePath, { session: false });
      }
      // Update Redux State with User Data
      this.props.dispatchSetUser(rsRes.userSessionData);

      this.setState({ loading: false });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Home.componentDidMount", error);
      displayToastMessage(
        "error",
        errorsToLabels.failedToFetchRequiredUserData
      );
      try {
        await this.SessionService.signOut();
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "Home.componentDidMount (this.SessionService.signOut())",
          error
        );
      } finally {
        this.props.history.replace(authRoutePath, {
          session: false,
        });
      }
    }
  }

  updateHomeView(e: Event | any, currentView: string) {
    e.preventDefault();
    this.setState({ currentView });
    return false;
  }

  renderHeader() {
    return (
      <header className="home-header-container">
        <div className="home-brand-label-container">
          <h1>{config.labels.brandName}</h1>
        </div>
        {this.renderHeaderActionItems()}
      </header>
    );
  }

  renderHeaderActionItems() {
    if (this.state.currentView === "Settings") {
      return (
        <div className="home-action-items-container">
          <button onClick={(e) => this.updateHomeView(e, "Properties")}>
            <i className="fa-solid fa-house"></i>
          </button>
        </div>
      );
    }
    return (
      <div className="home-action-items-container">
        <button
          className="settings-icon-container"
          onClick={(e) => this.updateHomeView(e, "Settings")}
        >
          <i className="fa-solid fa-gears"></i>
        </button>
      </div>
    );
  }

  updateSelectedProperty(
    e: Event | any,
    selectedProperty: Object | any,
    currentView: string
  ) {
    if (e) e.preventDefault();
    this.setState({ selectedProperty, currentView });
  }

  renderCurrentHomeView() {
    switch (this.state.currentView) {
      case "Products":
        return (
          <Products
            history={this.state.history}
            location={this.props.location}
            selectedProperty={this.state.selectedProperty}
            onUpdateSelectedProperty={this.updateSelectedProperty}
          />
        );
      case "Properties":
        return (
          <Properties
            history={this.state.history}
            location={this.props.location}
            onUpdateSelectedProperty={this.updateSelectedProperty}
          />
        );
      case "Settings":
        return (
          <Settings
            history={this.state.history}
            location={this.props.location}
          />
        );
      default:
        return (
          <Properties
            history={this.state.history}
            location={this.props.location}
          />
        );
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="home-container">
        {this.renderHeader()}
        <hr className="header-line" />
        <div className="home-content-type-container">
          {this.renderCurrentHomeView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: Function | any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home as any);
