import config from "../config";

const contactSupportLabel = `Please try again and/or contact support: ${config.emails.supportEmail}`;

export const postFormErrorLabel = (formLabel: string) => {
  return `${formLabel} Error: ${contactSupportLabel}`;
};

export const errorsToLabels = {
  userMustActivateAccount:
    "Activate your account using the token in your email titled 'Activate Account'",
  paymentInfoMissing: "Required payment information is missing.",
  failedToCancelSubscription: `Unable to cancel subscription: ${contactSupportLabel}`,
  failedToCreateNewSubscription: `Unable to create a new subscription: ${contactSupportLabel}`,
  failedToFetchPublicProfile: `Unable to fetch public profile: ${contactSupportLabel}`,
  failedToFetchPublicSchedule: `Unable to fetch public schedule: ${contactSupportLabel}`,
  failedToFetchRequiredUserData: `Unable to fetch required user data: ${contactSupportLabel}`,
  failedtoLoadBidAnalyticsData: `Unable to load bid analytics data: ${contactSupportLabel}`,
  failedToLoadBidsData: `Unable to load required bids data: ${contactSupportLabel}`,
  failedToLoadStreamsData: `Unable to load required streams data: ${contactSupportLabel}`,
  failedToLoadLiveFeedData: `Unable to load live feed (expired, inactive, or no payment): ${contactSupportLabel}`,
  failedToLoadPaymentsData: `Unable to load required payments data: ${contactSupportLabel}`,
  failedToLoadProductData: `Unable to load required product data: ${contactSupportLabel}`,
  failedToLoadPropertyData: `Unable to load required property data: ${contactSupportLabel}`,
  failedToPurchaseProduct: `Purchase failed: ${contactSupportLabel}`,
  failedToSignOut: `Unable to sign out: ${contactSupportLabel}`,
  failedToLoadRecommendations: `Unable to load recommendations: ${contactSupportLabel}`,
  failedToSubmitScheduledBid: `Unable to submit scheduled bid: ${contactSupportLabel}`,
  failedToSubmitSubscribedBid: `Unable to submit weekly subscribed message (free bid): ${contactSupportLabel}`,
  failedToLoadSubscriptionData: `Unable to load required subscription data: ${contactSupportLabel}`,
  failedToUpdateAccount: `Unable to update account information: ${contactSupportLabel}`,
  failedToUpdateAutoAcceptBids: `Unable to update 'Auto Accept Bids' flag: ${contactSupportLabel}`,
  failedToUpdateProfile: `Unable to update profile: ${contactSupportLabel}`,
  failedToUpdateProfileImage: `Unable to update profile image: ${contactSupportLabel}`,
  failedToUpdateSocialLinks: `Unable to update social link(s): ${contactSupportLabel}`,
  failedToUpdateSubscriptionProductPrice: `Unable to update subscription product price: ${contactSupportLabel}`,
  failedToOnboardToStripe: ` "Stripe connect creation (onboarding) failed. ${contactSupportLabel}`,
};
