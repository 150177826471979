import config from "../../config";
import React from "react";
import queryString from "query-string";
import "./index.css";
import { Mixpanel } from "../../utils/analytics";

class StripeCheckoutStatus extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(props.location.search);

    // Check for "success" flag
    let success = null;
    if (
      parsedQuery.status &&
      (parsedQuery.status === "0" || parsedQuery.status === "1")
    ) {
      success = Number(parsedQuery.status);
      Mixpanel.track("Stripe Checkout Status", {
        success,
      });
    }
    if (success === null) {
      window.location.href = "/404";
      return;
    }

    // Check for "awaitingfunds" flag (usually set by non-card payments on checkout)
    let awaitingfunds = false;
    if (parsedQuery.awaitingfunds && parsedQuery.awaitingfunds === "1") {
      awaitingfunds = true;
    }

    // Check for "pid" aka property ID
    let pid = null;
    if (
      parsedQuery.pid &&
      !isNaN(Number(parsedQuery.pid)) &&
      Number(parsedQuery.pid) > 0
    ) {
      pid = Number(parsedQuery.pid);
    }

    this.state = {
      success,
      awaitingfunds,
      pid,
      countdownSeconds: 5,
    };
  }

  componentDidMount() {
    const timerInterval = setInterval(() => {
      this.setState({ countdownSeconds: this.state.countdownSeconds - 1 });
    }, 1000);
    setTimeout(() => {
      clearInterval(timerInterval);
      if (this.state.pid !== null) {
        return window.location.replace(
          `${config.links.baseClientURL}/public/property?id=${this.state.pid}`
        );
      }
      window.location.replace(`${config.links.baseClientURL}/auth`);
    }, 5000);
  }

  render() {
    if (this.state.success === null) {
      return <p>Loading...</p>;
    }
    return (
      <div className="stripe-checkout-status-container">
        <div className="stripe-checkout-status-label-container">
          <p>
            Payment Status: {this.state.success === 1 ? "Succeeded" : "Failed"}
          </p>
          <p>
            {this.state.awaitingfunds
              ? "Your bid will be submitted if the transfer is successful."
              : ""}
          </p>
        </div>
        <div className="stripe-checkout-desc-label-container">
          <p>Redirecting in {this.state.countdownSeconds}</p>
        </div>
      </div>
    );
  }
}

export default StripeCheckoutStatus;
