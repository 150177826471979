import React from "react";
import { connect } from "react-redux";
import LoadingAnimation from "../../../components/LoadingAnimation";
import config from "../../../config";
import {
  DeleteProductResponse,
  ListProductsResponse,
} from "../../../interfaces/product";
import {
  CreatePropertyRequest,
  CreatePropertyResponse,
  ListPropertiesResponse,
  UpdatePropertyRequest,
  UpdatePropertyResponse,
} from "../../../interfaces/property";
import ProductService from "../../../services/Product";
import PropertyService from "../../../services/Property";
import SearchService from "../../../services/Search";
import SessionService from "../../../services/Session";
import { Mixpanel } from "../../../utils/analytics";
import { errorsToLabels, postFormErrorLabel } from "../../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import "./index.css";
import {
  dataURLtoBlob,
  sortFilterToOrderByKey,
  sortFilterToOrderByValue,
} from "../../../utils/convert";
import { SearchPropertiesResponse } from "../../../interfaces/search";

class Properties extends React.Component<any, any> {
  ProductService: ProductService;
  PropertyService: PropertyService;
  SearchService: SearchService;
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      currentView: "list", // list, create, edit
      loading: true,
      newPropertyForm: {
        userID: props.user.id,
        address: "",
        city: "",
        state: "",
        zipcode: "",
        propertyManagerEmail: "",
        propertyPasscode: "",
      } as CreatePropertyRequest,
      editPropertyForm: {} as UpdatePropertyRequest,
      selectedProperty: undefined,
      paginationOptions: {
        offset: 0,
        limit: 4,
        orderByKey: "created_at",
        orderByValue: "DESC",
      },
      selectedSearchFilter: config.properties.searchFilters[0],
      searchValue: "",
      selectedSortFilter: config.properties.sortFilters[0],
      totalFound: 0,
      properties: [],
      displayQRModal: false,
    };

    this.ProductService = new ProductService();
    this.PropertyService = new PropertyService();
    this.SearchService = new SearchService();
    this.SessionService = new SessionService();

    this.handleLoadMoreProperties = this.handleLoadMoreProperties.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.renderPropertiesHeader = this.renderPropertiesHeader.bind(this);
    this.renderLeftColumnActions = this.renderLeftColumnActions.bind(this);
    this.handleBackSegueAction = this.handleBackSegueAction.bind(this);
    this.renderCurrentView = this.renderCurrentView.bind(this);
    this.renderPropertiesList = this.renderPropertiesList.bind(this);
    this.renderPropertySearchBar = this.renderPropertySearchBar.bind(this);
    this.handleSearchProperties = this.handleSearchProperties.bind(this);
    this.renderPropertyListDetails = this.renderPropertyListDetails.bind(this);
    this.renderQRCode = this.renderQRCode.bind(this);
    this.renderLoadMoreButtons = this.renderLoadMoreButtons.bind(this);
    this.renderCreateNewPropertyForm =
      this.renderCreateNewPropertyForm.bind(this);
    this.handleCreateNewProperty = this.handleCreateNewProperty.bind(this);
    this.handleEditProperty = this.handleEditProperty.bind(this);
    this.handleDeleteProperty = this.handleDeleteProperty.bind(this);
    this.renderRightColumnActions = this.renderRightColumnActions.bind(this);
  }

  async componentDidMount() {
    try {
      // Did the user create a property? If not, show property onboarding view
      let listPropsRes: ListPropertiesResponse =
        await this.PropertyService.listProperties({
          userID: this.props.user.id,
          offset: this.state.paginationOptions.offset,
          limit: 1,
          orderByKey: this.state.paginationOptions.orderByKey,
          orderByValue: "ASC",
        });
      if (listPropsRes.error) {
        throw new Error(listPropsRes.error);
      }
      if (listPropsRes.totalFound === 0) {
        return this.setState({
          loading: false,
          currentView: "create",
        });
      }

      // Load initial data
      await this.handleSearchProperties();
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Properties.componentDidMount", error);
      displayToastMessage(
        "error",
        errorsToLabels.failedToFetchRequiredUserData
      );
    }
  }

  handleLoadMoreProperties(offset: number) {
    this.setState(
      {
        paginationOptions: {
          ...this.state.paginationOptions,
          offset,
        },
      },
      async () => {
        displayToastMessage("info", "Loading");
        await this.handleSearchProperties();
      }
    );
  }

  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel)
    );
  }

  async handleCreateNewProperty(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleCreateNewProperty");

      displayToastMessage("info", "Loading...");

      const cpRes: CreatePropertyResponse =
        await this.PropertyService.createProperty(this.state.newPropertyForm);
      if (cpRes.error) {
        throw new Error(cpRes.error);
      }
      if (cpRes.sessionURL) {
        Mixpanel.track("Subscription Checkout (started)", {});
        (window as Window).location = cpRes.sessionURL;
        return;
      }

      displayToastMessage("success", "Success");

      Mixpanel.track("New Property Created", {
        userID: this.state.newPropertyForm.userID,
      });

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProperties();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleCreateNewProperty handleSearchProperties()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleCreateNewProperty", error);
      displayToastMessage("error", postFormErrorLabel("Create Property"));
    }
  }

  async handleEditProperty(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleEditProperty");

      displayToastMessage("info", "Loading...");

      for (const entry of Object.entries(this.state.editPropertyForm)) {
        const [key, value] = entry;
        switch (key) {
          case "userID":
          case "propertyManagerEmail":
          case "propertyPasscode":
            break;
          default:
            if ((value as string).length === 0) {
              throw new Error(`"${key}" can not be empty`);
            }
        }
      }

      const upRes: UpdatePropertyResponse =
        await this.PropertyService.updateProperty(this.state.editPropertyForm);
      if (upRes.error) {
        throw new Error(upRes.error);
      }

      displayToastMessage("success", "Success");

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProperties();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleEditProperty handleSearchProperties()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleEditProperty", error);
      displayToastMessage("error", postFormErrorLabel("Edit Property"));
    }
  }

  async handleDeleteProperty(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleDeleteProperty");

      displayToastMessage("info", "Loading...");

      const reqBody = {
        userID: this.state.editPropertyForm.userID,
        id: this.state.editPropertyForm.id,
      };
      const cpRes: DeleteProductResponse =
        await this.PropertyService.deleteProperty(reqBody);
      if (cpRes.error) {
        throw new Error(cpRes.error);
      }

      displayToastMessage("success", "Success");

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProperties();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleDeleteProperty handleSearchProperties()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleDeleteProperty", error);
      displayToastMessage("error", postFormErrorLabel("Delete Property"));
    }
  }

  renderPropertiesHeader() {
    let heading = "Properties";
    switch (this.state.currentView) {
      case "create":
        heading = "Create";
        break;
      case "edit":
        heading = "Edit";
        break;
    }
    return (
      <div className="home-header row">
        {this.renderLeftColumnActions()}
        <h1 className="home-header-label col-11">{heading}</h1>
        {this.renderRightColumnActions()}
      </div>
    );
  }

  renderCurrentView() {
    switch (this.state.currentView) {
      case "list":
        return this.renderPropertiesList();
      case "create":
        return this.renderCreateNewPropertyForm();
      case "edit":
        return this.renderEditPropertyForm();
      default:
        return this.renderPropertiesList();
    }
  }

  renderPropertiesList() {
    return (
      <div className="properties-list-container row">
        {this.renderPropertiesHeader()}
        <h2>Total Found: {this.state.totalFound}</h2>
        {this.renderPropertySearchBar()}
        {this.renderPropertyListDetails()}
        {this.renderLoadMoreButtons()}
      </div>
    );
  }

  handleDownloadQRImage(e: Event | any, selectedProperty: Object | any) {
    e.preventDefault();

    displayToastMessage("info", "Downloading...");

    Mixpanel.track("QR Downloaded", {});

    const dataURL = (
      document.getElementById("qr-code")?.firstChild as any
    ).toDataURL();
    const blob = dataURLtoBlob(dataURL);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("href", url);
    a.setAttribute("download", `Property_${selectedProperty.address}_QR_Code`);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async handleSearchProperties(e?: Event | any) {
    try {
      if (e) {
        e.preventDefault();
      }

      displayToastMessage("info", "Loading...");

      logIssueNetworkRequest("handleSearchProperties");

      const spRes: SearchPropertiesResponse =
        await this.SearchService.searchProperties({
          userID: this.props.user.id,
          searchValue: this.state.searchValue,
          filterType: this.state.selectedSearchFilter.toLowerCase(),
          offset: this.state.paginationOptions.offset,
          limit: this.state.paginationOptions.limit,
          orderByKey: sortFilterToOrderByKey(this.state.selectedSortFilter),
          orderByValue: sortFilterToOrderByValue(this.state.selectedSortFilter),
        });
      if (spRes.error) {
        throw new Error(spRes.error);
      }

      this.setState({
        loading: false,
        totalFound: spRes.totalFound,
        properties: spRes.properties,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Properties.handleSearchProperties()", error);
      displayToastMessage("error", errorsToLabels.failedToLoadPropertyData);
    }
  }

  renderPropertySearchBar() {
    return (
      <div className="home-search-bar-container">
        <select
          className="pp-form-select form-select"
          value={this.state.selectedSearchFilter}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState({ selectedSearchFilter: evt.target.value });
          }}
        >
          {config.properties.searchFilters.map((filter: string, i: number) => (
            <option key={i} value={filter}>
              {filter}
            </option>
          ))}
        </select>
        <select
          className="pp-form-select form-select"
          value={this.state.selectedSortFilter}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState(
              { selectedSortFilter: evt.target.value },
              async () => {
                await this.handleSearchProperties(evt);
              }
            );
          }}
        >
          {config.properties.sortFilters.map((filter: string, i: number) => (
            <option key={i} value={filter}>
              {filter}
            </option>
          ))}
        </select>
        <input
          className="home-search-input"
          type="text"
          placeholder="..."
          value={this.state.searchValue}
          onSubmit={this.handleSearchProperties}
          onKeyDown={async (evt) => {
            if (evt.key === "Enter") {
              await this.handleSearchProperties(evt);
            }
          }}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState({ searchValue: evt.target.value });
          }}
        />
      </div>
    );
  }

  renderPropertyListDetails() {
    const propertyManagerEmail = (property: any) =>
      property.property_manager_email ? property.property_manager_email : "N/A";
    const propertyPasscode = (property: any) =>
      property.property_passcode ? property.property_passcode : "N/A";
    return this.state.properties.map((property: Object | any, i: number) => (
      <div
        key={i}
        className="home-list-details-container col-lg-6 col-12 home-list-col-padding-box"
      >
        <div className="home-list-col-wrapper row">
          <img
            className="home-list-col-img col-3"
            alt={`home-list-bg-${i}`}
            src={`${process.env.PUBLIC_URL}/home-list-img.jpg`}
            onClick={(e: Event | any) => {
              this.props.onUpdateSelectedProperty(e, property, "Products");
            }}
          ></img>
          <div className="home-list-col-details col-9">
            <div
              className="home-list-content-details"
              onClick={(e: Event | any) => {
                this.props.onUpdateSelectedProperty(e, property, "Products");
              }}
            >
              <h1>{property.address}</h1>
              <p>
                <strong>City:</strong> {property.city}
              </p>
              <p>
                <strong>State:</strong> {property.state}
              </p>
              <p>
                <strong>Zipcode:</strong> {property.zipcode}
              </p>
              <p>
                <strong>Property Manager:</strong>{" "}
                {propertyManagerEmail(property)}
              </p>
              <p>
                <strong>Property Passcode</strong> {propertyPasscode(property)}
              </p>
            </div>
            <hr className="home-list-details-line" />
            <div className="home-list-actions-container">
              <button
                className="edit-button edit-property"
                onClick={(e: Event | any) => {
                  e.preventDefault();
                  this.setState({
                    currentView: "edit",
                    editPropertyForm: {
                      id: property.id,
                      userID: this.props.user.id,
                      address: property.address,
                      city: property.city,
                      state: property.state,
                      zipcode: property.zipcode,
                      propertyManagerEmail: property.property_manager_email,
                      propertyPasscode: property.property_passcode,
                    },
                  });
                }}
              >
                Edit
              </button>
              <button
                className="open-qr-modal-button"
                onClick={(e: Event | any) => {
                  e.preventDefault();
                  this.setState(
                    { displayQRModal: true, selectedProperty: property },
                    () => {
                      displayToastMessage("info", "Loading QR code...");
                      setTimeout(() => {
                        const pic = document.getElementById("qr-code");
                        const QRCode = (window as any).QRCode;
                        new QRCode(
                          pic,
                          config.qrCode.generatePropertyLink(property.id)
                        );
                      }, 1000);
                    }
                  );
                }}
              >
                QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  renderLoadMoreButtons() {
    if (this.state.totalFound !== 0) {
      const { limit, offset } = this.state.paginationOptions;
      const disablePrevButton = offset === 0;
      const disableNextButton = offset + limit >= this.state.totalFound;
      return (
        <div className="load-buttons-container">
          <button
            disabled={disablePrevButton}
            className={`${
              disablePrevButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProperties(offset - limit)}
          >
            Prev
          </button>
          <button
            disabled={disableNextButton}
            className={`${
              disableNextButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProperties(offset + limit)}
          >
            Next
          </button>
        </div>
      );
    }
  }

  renderCreateNewPropertyForm() {
    return (
      <div className="create-properties-container row">
        {this.renderPropertiesHeader()}
        <form className="create-new-property-form">
          <div className="pl-input-container">
            <label>Address*</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="Address"
              value={this.state.newPropertyForm.address}
              onChange={(e) =>
                this.updateFormData(e, "newPropertyForm", "address")
              }
            />
          </div>
          <div className="pl-input-container">
            <label>City*</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="City"
              value={this.state.newPropertyForm.city}
              onChange={(e) =>
                this.updateFormData(e, "newPropertyForm", "city")
              }
            />
          </div>
          <div className="pl-input-container">
            <label>State* (N/A not applicable)</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="State"
              value={this.state.newPropertyForm.state}
              onChange={(e) =>
                this.updateFormData(e, "newPropertyForm", "state")
              }
            />
          </div>
          <div className="pl-input-container">
            <label>ZIP code* (N/A if not applicable)</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="ZIP code"
              value={this.state.newPropertyForm.zipcode}
              onChange={(e) =>
                this.updateFormData(e, "newPropertyForm", "zipcode")
              }
            />
          </div>
          <div className="pl-input-container">
            <label>Property Manager Email Address</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="Property Manager Email Address"
              value={this.state.newPropertyForm.propertyManagerEmail}
              onChange={(e) =>
                this.updateFormData(
                  e,
                  "newPropertyForm",
                  "propertyManagerEmail"
                )
              }
            />
          </div>
          <div className="pl-input-container">
            <label>Property Passcode</label>
            <input
              id="home-form-input"
              className="auth-view-form-input"
              type="text"
              placeholder="Property Passcode"
              value={this.state.newPropertyForm.propertyPasscode}
              onChange={(e) =>
                this.updateFormData(e, "newPropertyForm", "propertyPasscode")
              }
            />
          </div>
          <input
            id="home-action-button"
            className="auth-view-form-input-button common-button"
            type="button"
            value="Create"
            onClick={this.handleCreateNewProperty}
          />
        </form>
      </div>
    );
  }

  renderEditPropertyForm() {
    return (
      <div className="edit-properties-container row">
        {this.renderPropertiesHeader()}
        <div className="pl-input-container">
          <label>Address</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="Address"
            value={this.state.editPropertyForm.address}
            onChange={(e) =>
              this.updateFormData(e, "editPropertyForm", "address")
            }
          />
        </div>
        <div className="pl-input-container">
          <label>City</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="City"
            value={this.state.editPropertyForm.city}
            onChange={(e) => this.updateFormData(e, "editPropertyForm", "city")}
          />
        </div>
        <div className="pl-input-container">
          <label>State (N/A not applicable)</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="State"
            value={this.state.editPropertyForm.state}
            onChange={(e) =>
              this.updateFormData(e, "editPropertyForm", "state")
            }
          />
        </div>
        <div className="pl-input-container">
          <label>ZIP code (N/A if not applicable)</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="ZIP code"
            value={this.state.editPropertyForm.zipcode}
            onChange={(e) =>
              this.updateFormData(e, "editPropertyForm", "zipcode")
            }
          />
        </div>
        <div className="pl-input-container">
          <label>Property Manager Email Address</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="Property Manager Email Address"
            value={this.state.editPropertyForm.propertyManagerEmail}
            onChange={(e) =>
              this.updateFormData(e, "editPropertyForm", "propertyManagerEmail")
            }
          />
        </div>
        <div className="pl-input-container">
          <label>Property Passcode</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="Property Passcode"
            value={this.state.editPropertyForm.propertyPasscode}
            onChange={(e) =>
              this.updateFormData(e, "editPropertyForm", "propertyPasscode")
            }
          />
        </div>
        <input
          id="home-action-button"
          className="auth-view-form-input-button common-button"
          type="button"
          value="Save"
          onClick={this.handleEditProperty}
        />
        <input
          id="home-action-button"
          className="auth-view-form-input-button common-button"
          type="button"
          value="Delete"
          onClick={this.handleDeleteProperty}
        />
      </div>
    );
  }

  handleBackSegueAction(e: Event | any) {
    e.preventDefault();
    this.setState({ currentView: "list" });
  }

  renderLeftColumnActions() {
    switch (this.state.currentView) {
      case "create":
      case "edit":
        return (
          <div className="home-left-col-actions col-1">
            <button
              className="content-back-button"
              onClick={this.handleBackSegueAction}
            >
              <i className="fa-solid fa-circle-arrow-left"></i>
            </button>
          </div>
        );
    }
  }

  renderRightColumnActions() {
    if (this.state.currentView === "list") {
      return (
        <div className="home-right-col-actions col-1">
          <button
            className="add-button"
            onClick={(e: Event | any) => {
              e.preventDefault();
              this.setState({ currentView: "create" });
            }}
          >
            <i className="fa-solid fa-circle-plus"></i>
          </button>
        </div>
      );
    }
  }

  renderQRCode() {
    if (this.state.selectedProperty) {
      return (
        <div
          className={
            this.state.displayQRModal
              ? "generic-modal-container-active"
              : "generic-modal-container"
          }
        >
          <div className="generic-modal-container-overlay">
            <div id="qr-code"></div>
            <a
              className="view-public-store-link"
              href={config.qrCode.generatePropertyLink(
                this.state.selectedProperty.id
              )}
            >
              View Public Store
            </a>
            <button
              className="qr-container-button common-button"
              onClick={(e) =>
                this.handleDownloadQRImage(e, this.state.selectedProperty)
              }
            >
              Download
            </button>
            <button
              className="qr-container-button common-button"
              onClick={(e: Event | any) => {
                e.preventDefault();
                const qrCodeNode = document.getElementById("qr-code");
                (qrCodeNode as HTMLElement).textContent = "";
                this.setState({
                  displayQRModal: false,
                  selectedProperty: null,
                });
              }}
            >
              Close
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="common-home-content-container row">
        {this.renderCurrentView()}
        {this.renderQRCode()}
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Properties) as any;
