import CommonService from "../Common";
import { SignUpLogInRequest } from "../../interfaces/auth";

interface Session {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class SessionService implements Session {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/session";
    this.CommonService = new CommonService();
  }

  reviewSession() {
    return this.CommonService.fetch(`${this.pathPrefix}/review`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    });
  }

  logIn({ email, password }: SignUpLogInRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        email,
        password,
      }),
    });
  }

  signOut() {
    return this.CommonService.fetch(`${this.pathPrefix}/signout`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    });
  }
}

export default SessionService;
