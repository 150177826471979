import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userReducer from "./user";
import { History } from "history";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
  });

export default rootReducer;
