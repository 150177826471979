import React from "react";
import { connect } from "react-redux";
import config from "../../../config";
import { JoinProPlanResponse } from "../../../interfaces/payment";
import { IUser, UpdateAccountResponse } from "../../../interfaces/user";
import { setUser } from "../../../redux/actions/user";
import AccountService from "../../../services/Account";
import PaymentService from "../../../services/Payment";
import SessionService from "../../../services/Session";
import { Mixpanel } from "../../../utils/analytics";
import { errorsToLabels } from "../../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import { authRoutePath } from "../../Router";
import "./index.css";

class Settings extends React.Component<any, any> {
  AccountService: AccountService;
  PaymentService: PaymentService;
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      currentView: "account",
      navBarItems: ["Account"],
      password: "",
      updatedAccount: {
        email: "",
      },
    };

    this.AccountService = new AccountService();
    this.PaymentService = new PaymentService();
    this.SessionService = new SessionService();

    this.updateAccountForm = this.updateAccountForm.bind(this);
    this.renderCurrentSettingsView = this.renderCurrentSettingsView.bind(this);
    this.renderAccountSettings = this.renderAccountSettings.bind(this);
    this.handleSubscriptionsLinkClick =
      this.handleSubscriptionsLinkClick.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  renderCurrentSettingsView() {
    switch (this.state.currentView) {
      case "Account":
        return this.renderAccountSettings();
      default:
        return this.renderAccountSettings();
    }
  }

  updateAccountForm(evt: Event | any, formKey: string) {
    this.setState(
      {
        updatedAccount: {
          ...this.state.updatedAccount,
          [formKey]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state.updatedAccount, null, true)
    );
  }

  async handleUpdateAccount(evt: Event | any) {
    try {
      logIssueNetworkRequest("handleUpdateAccount");

      displayToastMessage("info", "Loading...");

      const uaRes: UpdateAccountResponse =
        await this.AccountService.updateAccount({
          userID: String(this.props.user.id),
          email: this.props.user.email,
          password: this.state.password,
          updatedAccount: this.state.updatedAccount,
        });
      if (uaRes.error) {
        throw new Error(uaRes.error);
      }

      displayToastMessage("success", "Updated account!");

      this.props.dispatchSetUser({
        ...this.props.user,
        ...this.state.updatedAccount,
      });

      this.setState({ updatedAccount: { email: "" } });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("AccountSettings.handleUpdateAccount", error);
      displayToastMessage("error", errorsToLabels.failedToUpdateAccount);
    }
  }

  async handleSignOut() {
    try {
      logIssueNetworkRequest("handleSignOut");

      displayToastMessage("info", "Signing out...");

      await this.SessionService.signOut();

      this.props.history.replace(authRoutePath, { session: false });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Settings.handleSignOut", error);
      displayToastMessage("error", errorsToLabels.failedToSignOut);
    }
  }

  async handleSubscriptionsLinkClick(evt: Error | any) {
    try {
      if (evt) evt.preventDefault();

      logIssueNetworkRequest("handleSubscriptionsLinkClick");

      displayToastMessage("info", "Loading...");

      const jppRes: JoinProPlanResponse = await this.PaymentService.joinProPlan(
        {
          userID: this.props.user.id,
        }
      );
      if (jppRes.error) {
        throw new Error(jppRes.error);
      }
      if (jppRes.sessionURL) {
        Mixpanel.track("Subscription Checkout (started)", {});
        (window as Window).location = jppRes.sessionURL;
        return;
      }

      (window as Window).location = config.links.stripeCustomerPortalURL;
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "Settings.handleSubscriptionsLinkClick",
        error
      );
      displayToastMessage("error", errorsToLabels.failedToLoadSubscriptionData);
    }
  }

  renderAccountSettings() {
    return (
      <div className="account-settings-container">
        <h1>Settings</h1>
        <h2
          className="payments-link"
          onClick={this.handleSubscriptionsLinkClick}
        >
          <strong>Pro Plan</strong>
        </h2>
        <h2 className="common-home-section-header-label payments-link">
          <a
            href={config.links.stripeConnectBaseURL}
            target="_blank"
            rel="noreferrer"
          >
            <strong>Transactions</strong>
          </a>
        </h2>

        <h2 className="common-home-section-header-label">
          Account Information
        </h2>
        <div className="current-info-container">
          <p>
            <strong>Email Address:</strong> {this.props.user.email}
          </p>
        </div>
        <h2 className="common-home-section-header-label">Update Information</h2>
        <input
          className="common-update-form-input positioned-form-input settings-input"
          type="text"
          placeholder="New Email Address"
          value={this.state.updatedAccount.email}
          onChange={(evt) => this.updateAccountForm(evt, "email")}
        />
        <input
          className="common-update-form-input positioned-form-input settings-input"
          type="password"
          placeholder="Current Password"
          value={this.state.password}
          onChange={(evt) => this.setState({ password: evt.target.value })}
        />
        <button
          id="positioned-form-action-button"
          className="common-update-form-button"
          type="button"
          onClick={this.handleUpdateAccount}
        >
          Update
        </button>

        <button
          id="positioned-form-action-button"
          className="common-update-form-button"
          type="button"
          onClick={this.handleSignOut}
        >
          Sign Out
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="common-home-content-container">
        {this.renderCurrentSettingsView()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
