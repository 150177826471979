export const logUpdatedState = (
  state: Object | any,
  stateKey: string | number | any,
  stateOnly: boolean = false
) => {
  if (process.env.NODE_ENV !== "production") {
    if (stateOnly) {
      return console.log(`STATE UPDATE: key:${stateKey}, (new) value:`, state);
    }
    console.log(`STATE UPDATE: key:${stateKey}, (new) value:`, state[stateKey]);
  }
};

export const logIssueNetworkRequest = (methodSelector: string) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(`Issuing network request from: ${methodSelector}`);
  }
};

export const logIssueNetworkRequestError = (
  methodSelector: string,
  error: Error
) => {
  console.log(`${methodSelector} error:`, error);
};
