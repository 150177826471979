import mixpanel from "mixpanel-browser";

const inProd = process.env.NODE_ENV === "production";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: !inProd,
  ignore_dnt: !inProd,
});

export const Mixpanel = {
  instance: mixpanel,
  identify(id: string) {
    if (inProd) mixpanel.identify(id);
  },
  track(name: string, props: any) {
    if (inProd) mixpanel.track(name, props);
  },
  getDistinctId() {
    if (inProd) return mixpanel.get_distinct_id();
  },
};
