import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { Mixpanel } from "../../../utils/analytics";
import { errorsToLabels, postFormErrorLabel } from "../../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import "./index.css";
import ProductService from "../../../services/Product";
import SearchService from "../../../services/Search";
import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductResponse,
  RecommendedProductData,
  RecommendProductsResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from "../../../interfaces/product";
import { SearchProductsResponse } from "../../../interfaces/search";
import {
  sortFilterToOrderByKey,
  sortFilterToOrderByValue,
} from "../../../utils/convert";
import config from "../../../config";
import { ProductCard } from "../../../components/ProductCard";

class Products extends React.Component<any, any> {
  ProductService: ProductService;
  SearchService: SearchService;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      currentView: "list", // list, "create", "edit"
      selectedProperty: props.selectedProperty,
      paginationOptions: {
        offset: 0,
        limit: 4,
      },
      selectedSearchFilter: config.products.searchFilters[0],
      searchValue: "",
      selectedSortFilter: config.products.sortFilters[0],
      totalFound: 0,
      products: [],
      recommendedProducts: [],
      newProductForm: {
        userID: props.user.id,
        propertyID: props.selectedProperty.id,
        name: "",
        price: 0,
        amount: 0,
      } as CreateProductRequest,
      editProductForm: {} as UpdateProductRequest,
      selectedProduct: null,
    };

    this.ProductService = new ProductService();
    this.SearchService = new SearchService();

    this.handleLoadMoreProducts = this.handleLoadMoreProducts.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.renderProductsHeader = this.renderProductsHeader.bind(this);
    this.renderLeftColumnActions = this.renderLeftColumnActions.bind(this);
    this.handleBackSegueAction = this.handleBackSegueAction.bind(this);
    this.renderProductsList = this.renderProductsList.bind(this);
    this.renderProductSearchBar = this.renderProductSearchBar.bind(this);
    this.handleSearchProducts = this.handleSearchProducts.bind(this);
    this.renderProductDetails = this.renderProductDetails.bind(this);
    this.handleRecommendProducts = this.handleRecommendProducts.bind(this);
    this.renderRecommendedProductsList =
      this.renderRecommendedProductsList.bind(this);
    this.renderCreateNewProductForm =
      this.renderCreateNewProductForm.bind(this);
    this.handleCreateNewProduct = this.handleCreateNewProduct.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.renderEditProductForm = this.renderEditProductForm.bind(this);
    this.handleEditProduct = this.handleEditProduct.bind(this);
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
    this.renderRightColumnActions = this.renderRightColumnActions.bind(this);
  }

  async componentDidMount() {
    await this.handleSearchProducts();
  }

  handleLoadMoreProducts(offset: number) {
    this.setState(
      {
        paginationOptions: {
          ...this.state.paginationOptions,
          offset,
        },
      },
      async () => {
        displayToastMessage("info", "Loading");
        await this.handleSearchProducts();
      }
    );
  }

  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel)
    );
  }

  handleBackSegueAction(e: Event | any) {
    if (this.state.currentView === "list") {
      return this.props.onUpdateSelectedProperty(e, null, "Properties");
    }
    e.preventDefault();
    return this.setState({ currentView: "list" });
  }

  renderProductsHeader() {
    let heading = "Products";
    let headingLabelColClass = "col-11";
    switch (this.state.currentView) {
      case "create":
        heading = "Create Product";
        break;
      case "recommendations":
        heading = "Recommendations";
        break;
      case "edit":
        heading = "Edit";
        break;
      case "list":
        headingLabelColClass = "col-10";
        break;
    }
    return (
      <div className="home-header row">
        {this.renderLeftColumnActions()}
        <h1 className={`home-header-label ${headingLabelColClass}`}>
          {heading}
        </h1>
        {this.renderRightColumnActions()}
      </div>
    );
  }

  renderLeftColumnActions() {
    return (
      <div className="home-left-col-actions col-1">
        <button
          className="content-back-button"
          onClick={this.handleBackSegueAction}
        >
          <i className="fa-solid fa-circle-arrow-left"></i>
        </button>
      </div>
    );
  }

  renderRightColumnActions() {
    if (this.state.currentView === "list") {
      return (
        <div className="home-right-col-actions col-1">
          <button
            className="add-button"
            onClick={(e: Event | any) => {
              e.preventDefault();
              this.setState({ currentView: "create" });
            }}
          >
            <i className="fa-solid fa-circle-plus"></i>
          </button>
        </div>
      );
    }
  }

  renderCurrentView() {
    switch (this.state.currentView) {
      case "list":
        return this.renderProductsList();
      case "recommendations":
        return this.renderRecommendedProductsList();
      case "create":
        return this.renderCreateNewProductForm();
      case "edit":
        return this.renderEditProductForm();
    }
  }

  async handleRecommendProducts(e: Event | any) {
    try {
      e.preventDefault();

      displayToastMessage("info", "Loading...", {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      });

      logIssueNetworkRequest("handleRecommendProducts");

      const rpRes: RecommendProductsResponse =
        await this.ProductService.recommendProducts({
          userID: this.props.user.id,
          propertyID: this.state.selectedProperty.id,
        });
      if (rpRes.error) {
        throw new Error(rpRes.error);
      }

      this.setState(
        {
          currentView: "recommendations",
          recommendedProducts: rpRes.products,
        },
        () => {
          displayToastMessage("success", "Success");
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Product.handleRecommendProducts()", error);
      displayToastMessage("error", errorsToLabels.failedToLoadRecommendations);
    }
  }

  renderProductsList() {
    return (
      <div className="products-list-container row">
        {this.renderProductsHeader()}
        <h2>Total found: {this.state.totalFound}</h2>
        <button
          className="pl-recommendations-button"
          onClick={this.handleRecommendProducts}
        >
          Need Recommendations? (AI)
        </button>
        {this.renderProductSearchBar()}
        {this.renderProductDetails()}
        {this.renderLoadMoreButtons()}
      </div>
    );
  }

  renderRecommendedProductsList() {
    return (
      <div className="products-list-container row">
        {this.renderProductsHeader()}
        {this.state.recommendedProducts.map(
          (product: RecommendedProductData | any, i: number) => {
            return (
              <ProductCard
                key={i}
                index={i}
                currentView={"Recommendations"}
                product={product}
                selectProduct={(e: Event | any) => {
                  e.preventDefault();
                  this.setState({
                    currentView: "create",
                    newProductForm: {
                      userID: this.props.user.id,
                      propertyID: this.props.selectedProperty.id,
                      name: product.name,
                      price: product.price,
                      amount: product.amount,
                    },
                  });
                }}
              />
            );
          }
        )}
      </div>
    );
  }

  selectProduct(e: Event | any, product: Object | any) {
    e.preventDefault();
    this.setState({
      currentView: "edit",
      editProductForm: {
        id: product.id,
        propertyID: this.props.selectedProperty.id,
        userID: this.props.user.id,
        name: product.name,
        price: product.price,
        amount: product.amount,
      },
    });
  }

  async handleSearchProducts(e?: Event | any) {
    try {
      if (e) {
        e.preventDefault();
      }

      displayToastMessage("info", "Loading...");

      logIssueNetworkRequest("handleSearchProducts");

      const spRes: SearchProductsResponse =
        await this.SearchService.searchProducts({
          userID: this.props.user.id,
          propertyID: this.state.selectedProperty.id as number,
          searchValue: this.state.searchValue,
          filterType: this.state.selectedSearchFilter.toLowerCase(),
          offset: this.state.paginationOptions.offset,
          limit: this.state.paginationOptions.limit,
          orderByKey: sortFilterToOrderByKey(this.state.selectedSortFilter),
          orderByValue: sortFilterToOrderByValue(this.state.selectedSortFilter),
        });
      if (spRes.error) {
        throw new Error(spRes.error);
      }

      this.setState({
        loading: false,
        totalFound: spRes.totalFound,
        products: spRes.products,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Product.handleSearchProducts()", error);
      displayToastMessage("error", errorsToLabels.failedToLoadProductData);
    }
  }

  renderProductSearchBar() {
    return (
      <div className="home-search-bar-container">
        <select
          className="pp-form-select form-select"
          value={this.state.selectedSearchFilter}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState({ selectedSearchFilter: evt.target.value });
          }}
        >
          {config.products.searchFilters.map((filter: string, i: number) => (
            <option key={i} value={filter}>
              {filter}
            </option>
          ))}
        </select>
        <select
          className="pp-form-select form-select"
          value={this.state.selectedSortFilter}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState(
              { selectedSortFilter: evt.target.value },
              async () => {
                await this.handleSearchProducts(evt);
              }
            );
          }}
        >
          {config.products.sortFilters.map((filter: string, i: number) => (
            <option key={i} value={filter}>
              {filter}
            </option>
          ))}
        </select>
        <input
          className="home-search-input"
          type="text"
          placeholder="..."
          value={this.state.searchValue}
          onSubmit={this.handleSearchProducts}
          onKeyDown={async (evt) => {
            if (evt.key === "Enter") {
              await this.handleSearchProducts(evt);
            }
          }}
          onChange={(evt) => {
            evt.preventDefault();
            this.setState({ searchValue: evt.target.value });
          }}
        />
      </div>
    );
  }

  renderProductDetails() {
    return this.state.products.map((product: Object | any, i: number) => {
      return (
        <ProductCard
          key={i}
          index={i}
          currentView={"Products"}
          product={product}
          selectProduct={this.selectProduct}
        />
      );
    });
  }

  renderLoadMoreButtons() {
    if (this.state.totalFound !== 0) {
      const { limit, offset } = this.state.paginationOptions;
      const disablePrevButton = offset === 0;
      const disableNextButton = offset + limit >= this.state.totalFound;
      return (
        <div className="load-buttons-container">
          <button
            disabled={disablePrevButton}
            className={`${
              disablePrevButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProducts(offset - limit)}
          >
            Prev
          </button>
          <button
            disabled={disableNextButton}
            className={`${
              disableNextButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMoreProducts(offset + limit)}
          >
            Next
          </button>
        </div>
      );
    }
  }

  async handleCreateNewProduct(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleCreateNewProduct");

      displayToastMessage("info", "Loading...");

      const cpRes: CreateProductResponse =
        await this.ProductService.createProduct(this.state.newProductForm);
      if (cpRes.error) {
        throw new Error(cpRes.error);
      }
      if (cpRes.sessionURL) {
        Mixpanel.track("Subscription Checkout (started)", {});
        (window as Window).location = cpRes.sessionURL;
        return;
      }

      displayToastMessage("success", "Success");

      Mixpanel.track("New Product Created", {
        ...this.state.newProductForm,
      });

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProducts();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleCreateNewProduct handleSearchProducts()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleCreateNewProduct", error);
      displayToastMessage("error", postFormErrorLabel("Create Product"));
    }
  }

  async handleEditProduct(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleEditProduct");

      displayToastMessage("info", "Loading...");

      for (const entry of Object.entries(this.state.editProductForm)) {
        const [key, value] = entry;
        switch (key) {
          case "id":
          case "userID":
          case "propertyID":
            break;
          default:
            if ((value as string).length === 0) {
              throw new Error(`"${key}" can not be empty`);
            }
        }
      }

      const upRes: UpdateProductResponse =
        await this.ProductService.updateProduct(this.state.editProductForm);
      if (upRes.error) {
        throw new Error(upRes.error);
      }

      displayToastMessage("success", "Success");

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProducts();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleEditProduct handleSearchProducts()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleEditProduct", error);
      displayToastMessage("error", postFormErrorLabel("Edit Product"));
    }
  }

  async handleDeleteProduct(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleDeleteProduct");

      displayToastMessage("info", "Loading...");

      const dpRes: DeleteProductResponse =
        await this.ProductService.deleteProduct({
          userID: this.state.editProductForm.userID,
          propertyID: this.state.editProductForm.propertyID,
          id: this.state.editProductForm.id,
        });
      if (dpRes.error) {
        throw new Error(dpRes.error);
      }

      displayToastMessage("success", "Success");

      this.setState(
        {
          loading: true,
          currentView: "list",
        },
        async () => {
          try {
            await this.handleSearchProducts();
          } catch (error: Error | any) {
            // Reload to see if session expired..
            logIssueNetworkRequestError(
              "handleDeleteProduct handleSearchProducts()",
              error
            );
            window.location.reload();
          }
        }
      );
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleDeleteProduct", error);
      displayToastMessage("error", postFormErrorLabel("Delete Product"));
    }
  }

  renderCreateNewProductForm() {
    return (
      <div className="properties-list-container">
        {this.renderProductsHeader()}
        <div className="pl-input-container">
          <label>Name*</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="Name*"
            value={this.state.newProductForm.name}
            onChange={(e) => this.updateFormData(e, "newProductForm", "name")}
          />
        </div>
        <div className="pl-input-container">
          <label>Price ($USD)*</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="number"
            placeholder="Price*"
            value={this.state.newProductForm.price}
            onChange={(e) => this.updateFormData(e, "newProductForm", "price")}
          />
        </div>
        <div className="pl-input-container">
          <label>Amount (Total Stock)*</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="number"
            placeholder="Amount*"
            value={this.state.newProductForm.amount}
            onChange={(e) => this.updateFormData(e, "newProductForm", "amount")}
          />
        </div>
        <input
          id="home-action-button"
          className="auth-view-form-input-button common-button"
          type="button"
          value="Create"
          onClick={this.handleCreateNewProduct}
        />
      </div>
    );
  }

  renderEditProductForm() {
    return (
      <div className="edit-products-container">
        {this.renderProductsHeader()}
        <div className="pl-input-container">
          <label>Name</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="text"
            placeholder="Name"
            value={this.state.editProductForm.name}
            onChange={(e) => this.updateFormData(e, "editProductForm", "name")}
          />
        </div>
        <div className="pl-input-container">
          <label>Price ($USD)</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="number"
            placeholder="Price"
            value={this.state.editProductForm.price}
            onChange={(e) => this.updateFormData(e, "editProductForm", "price")}
          />
        </div>
        <div className="pl-input-container">
          <label>Amount (Total Stock)</label>
          <input
            id="home-form-input"
            className="auth-view-form-input"
            type="number"
            placeholder="Amount"
            value={this.state.editProductForm.amount}
            onChange={(e) =>
              this.updateFormData(e, "editProductForm", "amount")
            }
          />
        </div>
        <input
          id="home-action-button"
          className="auth-view-form-input-button common-button"
          type="button"
          value="Save"
          onClick={this.handleEditProduct}
        />
        <input
          id="home-action-button"
          className="auth-view-form-input-button common-button"
          type="button"
          value="Delete"
          onClick={this.handleDeleteProduct}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="products-container common-home-content-container row">
        {this.renderCurrentView()}
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Products) as any;
