import React from "react";
import Lottie from "lottie-react";
import animationData from "../../animations/loading.json";
import "./index.css";

interface LoadingAnimationProps {
  style?: Object | any;
}

const LoadingAnimation = (props: LoadingAnimationProps) => (
  <div className="loading-animation-container">
    <Lottie
      animationData={animationData}
      style={props.style ?? { height: 246, width: 246 }}
      loop={true}
    />
  </div>
);

export default LoadingAnimation;
