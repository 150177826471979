import CommonService from "../Common";
import {
  JoinProPlanRequest,
  PayForProductRequest,
} from "../../interfaces/payment";

interface Payment {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class PaymentService implements Payment {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/payment";
    this.CommonService = new CommonService();
  }

  joinProPlan(reqBody: JoinProPlanRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/proplansub`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  payForProduct(reqBody: PayForProductRequest) {
    return this.CommonService.fetch(
      `${this.pathPrefix}/product/${reqBody.productID}`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(reqBody),
      }
    );
  }
}

export default PaymentService;
