import CommonService from "../Common";
import {
  ListPropertiesRequest,
  ListPublicPropertiesRequest,
  GetPropertyRequest,
  GetPublicPropertyRequest,
  SearchPublicPropertyStoreRequest,
  CreatePropertyRequest,
  UpdatePropertyRequest,
  DeletePropertyRequest,
  UpdatePropertyImageRequest,
} from "../../interfaces/property";

interface Property {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class PropertyService implements Property {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/property";

    this.CommonService = new CommonService();
  }

  listProperties(reqBody: ListPropertiesRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/list`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  listPublicProperties(reqBody: ListPublicPropertiesRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/list/public`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  getProperty(reqBody: GetPropertyRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${propertyID}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  getPublicProperty(reqBody: GetPublicPropertyRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/public/${propertyID}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  searchPublicPropertyStore(reqBody: SearchPublicPropertyStoreRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(
      `${this.pathPrefix}/public/ai/search/${propertyID}`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(reqBody),
      }
    );
  }

  createProperty(reqBody: CreatePropertyRequest) {
    if (
      !reqBody.propertyManagerEmail ||
      reqBody.propertyManagerEmail.length === 0
    ) {
      delete reqBody.propertyManagerEmail;
    }
    if (!reqBody.propertyPasscode || reqBody.propertyPasscode.length === 0) {
      delete reqBody.propertyPasscode;
    }

    return this.CommonService.fetch(`${this.pathPrefix}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  updateProperty(reqBody: UpdatePropertyRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    if (
      !reqBody.propertyManagerEmail ||
      reqBody.propertyManagerEmail.length === 0
    ) {
      delete reqBody.propertyManagerEmail;
    }

    return this.CommonService.fetch(`${this.pathPrefix}/${propertyID}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  updatePropertyImage(reqBody: UpdatePropertyImageRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${propertyID}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  deleteProperty(reqBody: DeletePropertyRequest) {
    const propertyID = Number(reqBody.id);

    delete reqBody.id;

    return this.CommonService.fetch(`${this.pathPrefix}/${propertyID}`, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default PropertyService;
