import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

const initialState = {};

let middleware;
if (process.env.NODE_ENV === "production") {
  middleware = compose(applyMiddleware(routerMiddleware(history), thunk));
} else {
  middleware = compose(
    applyMiddleware(routerMiddleware(history), thunk, createLogger())
  );
}

export const store = createStore(
  createRootReducer(history),
  initialState,
  middleware
);
