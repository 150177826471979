import { IUserAction } from "../actions/user";
import { SET_USER, CLEAR_USER } from "../constants";

const userReducer = (state = {}, action: IUserAction | any) => {
  switch (action.type) {
    case SET_USER: {
      return action.user;
    }
    case CLEAR_USER:
      return {};
    default:
      return state;
  }
};

export default userReducer;
