import CommonService from "../Common";
import {
  SearchPropertiesRequest,
  SearchPublicPropertiesRequest,
  SearchProductsRequest,
  SearchPublicProductsRequest,
} from "../../interfaces/search";

interface Search {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class SearchService implements Search {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/search";
    this.CommonService = new CommonService();
  }

  searchProperties(reqBody: SearchPropertiesRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/property`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  searchPublicProperties(reqBody: SearchPublicPropertiesRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/property/public`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  searchProducts(reqBody: SearchProductsRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/product`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  searchPublicProducts(reqBody: SearchPublicProductsRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/product/public`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default SearchService;
