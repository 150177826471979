import { ComponentProps } from "react";
import "./index.css";

const PageNotFound = (props: ComponentProps<any>) => {
  return (
    <div className="page-not-found-container">
      <p>404 Page Not Found</p>
      <a href="/home">Home</a>
    </div>
  );
};

export default PageNotFound;
