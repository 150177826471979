import "./index.css";

export const ProductCard = (props: any) => {
  let buttonText = "Buy";
  switch (props.currentView) {
    case "Products":
      buttonText = "Edit";
      break;
    case "Recommendations":
      buttonText = "Add";
      break;
    default:
      break;
  }
  return (
    <div
      key={props.index}
      className="home-list-details-container col-lg-6 col-12 home-list-col-padding-box"
    >
      <div className="home-list-col-wrapper row">
        <img
          className="home-list-col-img col-3"
          alt={`home-list-bg-${props.key}`}
          src={`${process.env.PUBLIC_URL}/home-list-img.jpg`}
          onClick={(e: Event | any) => {
            props.selectProduct(e, props.product);
          }}
        ></img>
        <div className="home-list-col-details col-9">
          <div
            className="home-list-content-details"
            onClick={(e: Event | any) => {
              props.selectProduct(e, props.product);
            }}
          >
            <h1>{props.product.name}</h1>
            <p>
              <strong>Price:</strong> ${props.product.price}
            </p>
            <p>
              <strong>Amount:</strong> {props.product.amount}
            </p>
          </div>
          <hr className="home-list-details-line" />
          <div className="home-list-actions-container">
            <button
              className="edit-button edit-product"
              onClick={(e: Event | any) =>
                props.selectProduct(e, props.product)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
